@font-face {
    font-family: 'museo-sans';
    src: url(./font/museosans_500-webfont.woff2) format('woff2'),
         url(./font/museosans_500-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'museo-sans';
    src: url(./font/museosans_500_italic-webfont.woff2) format('woff2'),
         url(./font/museosans_500_italic-webfont.woff) format('woff');
    font-weight: normal;
    font-style: italic;
}

body {
  margin: 0;
  padding: 0;
  font-family: museo-sans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  font-family: museo-sans, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

abbr[title], acronym[title] {
  text-decoration: none;
}

input, textarea, select, button {
  font-family: museo-sans, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.App {
    font-family: sans-serif;
}

ul.StepLabels,
li.StepLabel {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.StepLabels {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    height: 60px;
}

ul.StepLabels li.StepLabel {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    background-color: #E6E7E8;
    position: relative;
}

ul.StepLabels li.StepLabel.StepLabelActive {
    background-color: #026DB5;
    color: white;
}

ul.StepLabels li.StepLabel .StepLabelContent {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 20px;
}

ul.StepLabels li.StepLabel .StepLabelContent span {
    display: block;
    background-color: white;
    color: blue;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 8px;
}

ul.StepLabels li.StepLabel:not(:first-child) {
    margin-right: -40px;
}

ul.StepLabels
li.StepLabel:not(:first-child):not(:last-child)
.StepLabelContent {
    padding-right: 60px;
    margin: 36px;
}

ul.StepLabels li.StepLabel:first-child .StepLabelTriangle {
    display: none;
}

ul.StepLabels li.StepLabel .StepLabelTriangle {
    width: 40px;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: #E6E7E8;
    fill: #E6E7E8;
}

ul.StepLabels li.StepLabel.StepLabelActive .StepLabelTriangle {
    background-color: #026DB5;
}

ul.StepLabels
li.StepLabel.StepLabelActive
+ li.StepLabel
.StepLabelTriangle {
    fill: #026DB5;
}

#okta-sign-in {
    width: unset;
    height: unset;
    margin: 0;
    font-family: museo-sans, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
}

#okta-sign-in.auth-container.main-container {
    background-color: #F5F5F5;
}

#okta-sign-in.auth-container h2, #okta-sign-in.auth-container h3 {
    font-size: 32px;
    font-weight: 900;
    color: #026DB5;
    text-align: left;
}

#okta-sign-in.auth-container .okta-sign-in-header {
    display: none;
}

#okta-sign-in.auth-container .button-primary {
    border-radius: 30px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 700;
    font-size: 16px;
    margin-left: auto;
    margin-right: 0;
    width: 50%;
}

#okta-sign-in.auth-container input[type=button], #okta-sign-in.auth-container input[type=submit] {
    font-family: museo-sans, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#okta-sign-in .o-form .o-form-label, #okta-sign-in .o-form input, #okta-sign-in .o-form label, #okta-sign-in .o-form textarea {
    font-size: 20px;
    color: #333333;
}

#okta-sign-in .o-form .input-fix, #okta-sign-in .o-form .textarea-fix {
    border-radius: 5px;
    border-color: #E3E3E3;
    border-width: 1px;
    height: 62px;
    font-size: 20px;
}

#okta-sign-in.auth-container .link, #okta-sign-in.auth-container .link:active, #okta-sign-in.auth-container .link:hover, #okta-sign-in.auth-container .link:link, #okta-sign-in.auth-container .link:visited {
    color: #026DB5;
    font-weight: 700;
    font-size: 20px;
    text-decoration: underline;
}

#okta-sign-in .o-form .custom-checkbox label {
    font-size: 18px;
}

#okta-sign-in .o-form .input-fix input, #okta-sign-in .o-form .textarea-fix input {
    font-size: 20px;
}

#okta-sign-in .focused-input, #okta-sign-in .link.help:focus {
    box-shadow: none;
}

#okta-sign-in .o-form-explain.o-form-input-error {
    color: #e13023;
}

#okta-sign-in .o-form-input-error .icon-16 {
    color: #e13023;
}

#okta-sign-in .custom-checkbox label.checked.focus {
    color: #153261;
    font-weight: bold;
}

#okta-sign-in.auth-container .button-primary:focus-visible {
    background: #153261;
}

#okta-sign-in.auth-container a.link.help:focus-visible {
    outline: 1px solid #333333;
    padding: 5px;
    margin: -5px;
}
